.whyshippy-section-container {
  display: flex;
  font-family: 'DM Sans Regular';
}


.whyshippy-section-title {
  flex: 1;
  color: #FFF;
  text-align: center;
  font-size: 4.2rem; 
  line-height: 1;
}



/* Media Query */
@media (max-width: 1380px) {
  .whyshippy-section-title {
    font-size: 3.5rem; 
  }
}

@media (max-width: 768px) {
  .whyshippy-section-title {
    font-size: 3rem; 
  }
}

@media (max-width: 600px) {

  
}


@media (max-width: 400px) {
  
}
