.rate-calculator-container {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    margin: 0 auto;
    color: #000;
}

.rate-calculator-country-select-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 1rem;
    width: 50%;
}

.rate-calculator-input {
    padding-left: 0.5rem;
    margin-top: 0.7rem;
    font-size: 1.1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}




/* Media Query */
@media (max-width: 1380px) {
}
  
@media (max-width: 768px) {
    .rate-calculator-container {
        flex-direction: column;
        align-items: center;
    }

    .rate-calculator-country-select-container {
        padding-right: 0rem;
        padding-bottom: 2rem;
        width: 100%;
    }

    .rate-calculator-input {
        padding-right: 0rem;
    }
    
}

@media (max-width: 600px) {

}

@media (max-width: 400px) {

}
  