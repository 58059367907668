.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  margin: auto;
  border-radius: 2rem;
  margin-top: 6rem;
  margin-bottom: 6rem;
  padding-bottom: 4rem;
}

.product-worktogether-description {
  color: black;
  font-size: 3rem;
  line-height: normal;
  font-family: "Google Sans Regular";
  text-align: center;
}
