.main {
  display: flex;
  flex-direction: column;
}

.projects-header {
  font-size: 4rem;
  font-family: "Poppins Light";
  margin: 0rem 0rem 5rem;
}

@media (max-width: 1380px) {
  .projects-header {
    font-size: 4rem;
    margin: 0rem 0rem 0rem;
  }
}

@media (max-width: 600px) {
  .projects-header {
    font-size: 4rem;
    margin: 0rem 0rem 1rem;
  }
}
