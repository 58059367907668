.contact-main {
  width: 100%;
}

.basic-contact {
  margin-left: 5%;
  margin-right: 5%;
}

.contact-me-container {
  display: flex; 
  align-items: center; 
  width: 100%; 
  margin: 0 auto; 
  flex-direction: column;
}

.contact-me-container-input-container {
  display: flex; 
  flex-direction: row;
  justify-content: center; 
  width: 50%; 
  margin-top: 2rem;
}

.contact-me-container-input-container-input {
  flex: 0.8; 
  font-size: 1.4rem; 
  padding-top: 1rem; 
  padding-bottom: 1rem; 
  padding-left: 0.5rem; 
  padding-right: 0.5rem;
}

.contact-me-container-input-container-button {
  display: flex; 
  flex: 0.2; 
  cursor: pointer; 
  padding: 1rem; 
  background-color: #6738EE; 
  color: #FFF; 
  align-items: center; 
  justify-content: center; 
  margin-left: 1rem; 
  text-align: center;
}

.contact-component-input-email-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40%;
  margin-top: 2rem;
}

.contact-component-input-email-container-input {
  display: flex;
  flex: 0.2;
  cursor: pointer;
  padding: 1rem;
  background-color: #6738EE;
  color: #FFF;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  text-align: center;
}

@media (max-width: 1000px) {
  .contact-me-container-input-container {
    width: 60%;
    flex-direction: column;
  }

  .contact-me-container-input-container-button {
    margin-top: 1rem;
    margin-left: 0rem; 
  }

  .contact-component-input-email-container {
    width: 80%;
  }
  
  
}

@media (max-width: 768px) {
  .contact-me-container-input-container {
    width: 90%;
  }

  .contact-me-container-input-container-input {
    font-size: 1.2rem;
  }

  .contact-component-input-email-container {
    flex-direction: column;
    width: 90%;
  }

  .contact-component-input-email-container-input {
    margin-top: 1rem;
    margin-left: 0rem;
  }

  .contact-component-input-email-container-input {

  }
}
