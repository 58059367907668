.main {
  text-align: center;
}

.projects-heading-mobile {
  display: none;
}

.projects-heading-desktop {
  text-align: center;
  line-height: 1;
  font-size: 4rem;
  font-weight: 400;
}

.projects-main-div {
  display: flex;
  overflow: hidden;
}

.projects-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.projects-text {
  margin: 2rem 0rem 0rem;
  flex: 1;
  text-align: left;
  font-size: 1.5rem;
  font-family: "Poppins Light";
}

.projects-text-div {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem 0rem;
}

.projects-link {
  margin-top: 1rem;
  text-align: center;
  text-decoration: none;
  color: white;
}

.projects-image-div > * {
  max-width: 100%;
}

/* Media Query */
@media (max-width: 1380px) {
  .projects-main-div {
  }
}

@media (max-width: 768px) {
  .main {
    text-align: center;
  }

  .projects-main-div {
    flex-direction: column;
  }

  .projects-image-div {
    display: flex;
    text-align: center;
    flex-direction: column;
  }

  .projects-heading-desktop {
    display: none;
  }

  .projects-link {
    margin: 0rem 0rem 1rem;
  }

  .projects-text {
    margin: 0rem 0rem 2rem;
  }

  .projects-heading-mobile {
    display: flex;
    justify-content: center;
    margin: 2rem 0rem 0rem;
    font-size: 4rem;
    font-weight: 400;
  }
}

@media (max-width: 600px) {
  .projects-main-div {
    margin: 0rem 0rem 1rem;
  }
}
