.whyshippy-project-description {
  font-family: 'DM Sans Regular';
  font-size: 1.5rem;
  line-height: 1.4;
}

.whyshippy-project-main-div {
  display: flex;
  overflow: hidden;
  margin-bottom: 2rem;
  gap: 5rem;
}

.whyshippy-project-title {
  font-size: 40px;
  line-height: 1;
}

.whyshippy-project-heading-mobile {
  display: none;
}

.whyshippy-project-heading-desktop {
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 400;
}

.whyshippy-project-header {
  font-size: 60px;
  line-height: normal;
  text-align: center;
}

.whyshippy-project-text {
  font-size: 20px;
}

.whyshippy-project-text-div {
  display: flex;
  flex-direction: column;
}

.whyshippy-project-text-div-left {
}

.whyshippy-project-text-div-right {
  margin-right: 2rem;
}

.whyshippy-project-image-div > * {
  max-width: 100%;
}

.whyshippy-project-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
}

.whyshippy-project-description-container {
  flex: 1;
}

/* Media Query */
@media (max-width: 1380px) {
  .whyshippy-project-title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .whyshippy-project-main-div {
    flex-direction: column;
  }

  .whyshippy-project-heading-desktop {
    display: none;
  }

  .whyshippy-project-text {
    margin: 0rem 0rem 2rem;
  }

  .whyshippy-project-heading-mobile {
    display: flex;
    justify-content: center;
    margin: 2rem 0rem 0rem;
    font-size: 3rem;
    font-weight: 400;
  }

  .whyshippy-project-title {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .whyshippy-project-main-div {
    margin: 0rem 0rem 1rem;
  }
}
