.product-section-container {
  display: flex;
  flex-direction: column;
}

.product-ecommerce-cta-container {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.product-ecommerce-cta {
  padding: 1rem 4rem 1rem;
  border-radius: 1rem;
  text-align: center;
  background-color: #8f21fc;
  font-weight: 500;
  color: white;
  text-decoration: none;
}

.product-ecommerce-cta:hover {
  opacity: 0.8;
}

.product-section-text-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  margin: auto;
  min-width: 80%;
  margin-top: 2rem;
}

.product-section-title {
  font-size: 3.5rem;
  line-height: 1;
  margin-top: 1rem;
}

.product-section-subtitle {
  margin-top: 1rem;
  font-size: 1.3rem;
  line-height: 1;
}

@media (max-width: 768px) {
  .product-section-container {
    margin-top: 1rem;
  }

  .product-section-text-container {
    text-align: center;
  }

  .product-section-title {
    font-size: 3rem;
  }

  .product-section-subtitle {
    margin-top: 1rem;
  }

  .product-section-text-container {
    margin-bottom: 2rem;
  }
}
