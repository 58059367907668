.image {
  width: 100%;
  transform: rotate(0.00000000001deg);
}

@media (max-width: 1380px) {
  .image-div {
    height: 15rem;
  }
}

@media (max-width: 768px) {
  .image-div {
    height: 10rem;
  }
}
