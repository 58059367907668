.hero-main {
  display: flex;
  flex-direction: row;
  font-family: 'DM Sans Regular';
}

.hero-main-container {
  padding-top: 5rem;
  padding-bottom: 20rem;
  max-width: 70%;
  margin: 0 auto; 
}

.hero-main-rate-calulator-container {
  flex: 0.6;
}

.hero-main-title-calculator-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.hero-main-title {
  font-size: 4rem;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

/* Media Query */
@media (max-width: 1380px) {
  .hero-main-container {
    padding-top: 0rem;
    padding-bottom: 5rem;
    max-width: 80%; 
  }
}

@media (max-width: 1000px) {
  .hero-main-title-calculator-container {
    flex-direction: column;
  }
  .hero-main-rate-calulator-container {
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .hero-main-container {
    max-width: 90%;
  }
  .hero-main-title {
    font-size: 3.5rem;
  }
}

@media (max-width: 600px) {

  .hero-main-title {
    font-size: 3rem;
  } 
}

@media (max-width: 400px) {

  .hero-main-title {
    font-size: 3rem;
  } 
}
