.aboutme-heading-mobile {
  display: none;
}

.aboutme-heading-desktop {
  font-size: 4rem;
  font-weight: 400;
}

.aboutme-main-div {
  display: flex;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 10rem;
}

.aboutme-description {
  font-family: "Poppins Light";
  font-size: 1.5rem;
  line-height: 1.5;
}
.aboutme-description-link {
  color: white;
}

.aboutme-text {
  margin: 1.5rem 0rem 0rem;
  flex: 1;
  text-align: left;
  font-size: 1.5rem;
  font-family: "Poppins Light";
}

.aboutme-text-div {
  display: flex;
  flex-direction: column;
}

.aboutme-links-and-software-skills-container {
  display: flex;
  flex-direction: column;
}

.aboutme-link {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.aboutme-text-div {
  margin: 0rem 2rem 0rem;
}

.aboutme-image-div > * {
  margin-top: 100px;
}

.software-skills-container {
  display: flex;
  justify-content: center;
}

/* Media Query */
@media (max-width: 1380px) {
  .aboutme-title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .aboutme-main-div {
    flex-direction: column;
  }

  .aboutme-text-div {
    margin: 0rem 0rem 0rem;
  }

  .aboutme-heading-desktop {
    display: none;
  }

  .aboutme-link {
    margin: 0rem 0rem 1rem;
  }

  .aboutme-text {
    margin: 0rem 0rem 2rem;
  }

  .aboutme-description {
    text-align: center;
    font-family: "Poppins Light";
    font-size: 1.3rem;
    line-height: 1;
  }

  .aboutme-heading-mobile {
    display: flex;
    justify-content: center;
    margin: 2rem 0rem 0rem;
    font-size: 4rem;
    font-weight: 400;
  }

  .aboutme-title {
    font-size: 30px;
    text-align: center;
  }
}

@media (max-width: 600px) {
}
