.hello-item-main-div {
  display: flex;
  overflow: hidden;
  margin-bottom: 4rem;
  line-height: normal;
  margin: 3rem auto;
  max-width: 95%;
}

.what-is-tidy-subtitle {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.what-is-tidy-list-items {
  font-size: 1.4rem;
  margin-bottom: 3rem;
}

.twitter-link {
  text-decoration: underline;
  color: #FFF;
}

.hello-item-heading-left-mobile {
  display: none;
}

.hello-item-heading-right-mobile {
  display: none;
}

.hello-item-heading-left-desktop {
  font-size: 3rem;
}

.hello-item-heading-right-desktop {
  font-size: 3rem;
  font-weight: 400;
}

.hello-item-url-desktop-container {
  text-align: center;
}

.hello-item-url-mobile-container {
  display: none;
}

.hello-item-url {
  color: white;
}

.hello-item-text-div-left {
  margin-left: 3rem;
}

.hello-item-text-div-right {
  margin-right: 3rem;
}

.hello-item-image-div-left {
  min-width: 45%;
}


.hello-item-description-desktop {
  line-height: normal;
  font-size: clamp(1rem, 2vw, 1.3rem);
  /* font-size: 1.3rem; */
}

.hello-item-description-mobile {
  display: none;
}

/* Media Query */
@media (max-width: 1380px) {
  .hello-item-title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .hello-item-main-div {
    flex-direction: column;
  }

  .hello-item-heading-desktop {
    display: none;
  }

  .hello-item-heading-left-desktop {
    display: none;
  }

  .hello-item-url-desktop-container {
    display: none;
  }

  .hello-item-url-mobile-container {
    display: flex;
    justify-content: center;
  }

  .hello-item-heading-left-mobile {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 400;
  }

  .hello-item-heading-right-desktop {
    display: none;
  }

  .hello-item-heading-right-mobile {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 400;
  }
  .hello-item-description-desktop {
    display: none;
  }

  .hello-item-description-mobile {
    display: inherit;
    font-size: clamp(1rem, 2vw, 1.3rem);
    font-weight: 100;
  }

  .hello-item-text-div-left {
    margin-left: 0rem;
  }

  .hello-item-text-div-right {
    margin-left: 0rem;
  }

  .hello-item-title {
    font-size: 30px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .hello-item-main-div {
    margin: 1rem auto;
  }


  .what-is-tidy-subtitle {
    font-size: 1.5rem;
  }

  .what-is-tidy-list-items {
    font-size: 0.8rem;
  }
}
